import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/mnt/d/ShapeGanicGatsby/src/components/blog/layout.js";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Disclosure from "../../../components/blog/legal/affiliateMarketing.de.mdx";
import BlogOverview from "../../../components/blog/overview/blogOverview.de.mdx";
import Layout from "../../../components/blog/layout.de";
export const query = graphql`
  {
    mdx(slug: { eq: "wie-berechne-ich-meinen-kcal-bedarf/index.de" }) {
      frontmatter {
        main_image {
          publicURL
        }
        image_first {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_first_alt
        image_first_desc
        image_first_credit_text
        image_first_credit_link
        image_second {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_second_alt
        image_second_desc
        image_second_credit_text
        image_second_credit_link
        image_third {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_third_alt
        image_third_desc
        image_third_credit_text
        image_third_credit_link
        image_app_promotion {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_app_promotion_alt
        image_app_promotion_desc
        image_app_promotion_credit_text
        image_app_promotion_credit_link
        date(formatString: "D. MMMM YYYY", locale: "de-DE")
      }
      fields {
        readingTime {
          minutes
        }
      }
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const SEO = makeShortcode("SEO");
const Title = makeShortcode("Title");
const Image = makeShortcode("Image");
const Listing = makeShortcode("Listing");
const NavLinkGlobal = makeShortcode("NavLinkGlobal");
const AffiliateLink = makeShortcode("AffiliateLink");
const AppPromotion = makeShortcode("AppPromotion");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO lang={props.pageContext.frontmatter.lang} description={props.pageContext.frontmatter.summary} title={props.pageContext.frontmatter.title} keywords={props.pageContext.frontmatter.keywords} imageUrl={props.data.mdx.frontmatter.main_image.publicURL} mdxType="SEO" />
    <Title title={props.pageContext.frontmatter.title} date={props.data.mdx.frontmatter.date} readingTime={props.data.mdx.fields.readingTime.minutes} mdxType="Title" />
    <p>
  <b>{props.pageContext.frontmatter.summary}</b> Lerne anschaulich anhand von
  Beispielen wie du deinen Grund-, Leistungs- und Gesamtumsatz berechnen kannst.
  Die Berechnung ist dir auf Dauer zu aufwending? Am Ende des Artikels erfährst
  du, welche App die zuverlässigste zum Berechnen deines Kalorienbedarfs ist.
    </p>
    <Image image={getImage(props.data.mdx.frontmatter.image_first)} imageAlt={props.data.mdx.frontmatter.image_first_alt} imageDesc={props.data.mdx.frontmatter.image_first_desc} imageAuthor={props.data.mdx.frontmatter.image_first_credit_text} imageLink={props.data.mdx.frontmatter.image_first_credit_link} mdxType="Image" />
    <h2>{`Was genau sind Kalorien?`}</h2>
    <p>
  Bevor du lernst wie du deinen Grundumsatz berechnest, solltest du dich
  vergewissern, dass du auch verstanden hast, was Kalorien überhaupt sind und
  welche Bedeutung sie für deinen Körper haben.
    </p>
    <p>
  Du siehst sie praktisch auf allen Lebensmitteln, zumindest auf den industriell
  hergestellten. <b>Nährwertangaben</b> geben Auskunft darüber, welche
  Makronährstoffe in einem Lebensmittel vorhanden sind. Insbesondere zeigen sie
  dir, wie viele Kalorien eine Portion des Lebensmittels liefert. Diese Kalorien
  werden auch als <b>Brennwert</b> bezeichnet und sagen dir im Grunde wie viel
  Energie dadurch deinem Körper bereitgestellt wird. Die Kalorien werden auf den
  Nährwertangaben als <b>kcal</b> angegeben, hierbei steht das <b>k</b> für kilo
  wie in Kilogramm, was jedoch im Allgemeinen Sprachgebrauch vernachlässigt
  wird. Doch was hat das alles mit Energie zu tun und weshalb braucht dein
  Körper überhaupt diese Energie?
    </p>
    <p>
  Gleichgültig ob du denkst, gehst, stehst, schläfst, atmest, rennst, sitzt oder
  dich einfach nur aufregst. Ja sogar für die Verdauung braucht dein Körper
  eines: <b>Energie</b>. Diese Energie muss von außen zugeführt werden und zwar
  durch die Nahrung. Bei der Nahrung wird der Brennwert, also der Energiegehalt,
  in Kilojoule (kJ) oder in der gebräuchlicheren Angabe Kalorien (kcal)
  angegeben. Dein Körper erhält die Energie aus der Nahrung indirekt über die
  Makronährstoffe <b>Fett</b>, <b>Kohlenhydrate</b> und <b>Eiweiß</b>.{" "}
  <b>1 g Fett</b> liefert <b>9.3 kcal</b> während sowohl <b>Kohlenhydrate</b>{" "}
  als auch <b>Eiweiß</b> jeweils <b>4.1 kcal</b> pro <b>Gramm</b> liefern.
    </p>
    <h2>{`Was versteht man unter einem Grundumsatz?`}</h2>
    <p>
  Stell dir vor du liegst den ganzen Tag auf der Couch und guckst Netflix. Du
  bewegst nicht einmal deine Finger, um die Staffel deiner Lieblingsserie zu
  wechseln. Jetzt denkst du dir, dass du keine Kalorien verbrauchen würdest,
  schließlich hast du ja nichts getan. Weit gefehlt! Auch wenn du absolut nichts
  tust, so benötigt dein Körper dennoch Energie zum Leben. Egal ob
  Stoffwechselprozess wie die Verdauung oder Regeneration, Denkvorgänge oder die
  Regulierung und Aufrechterhaltung deiner Körpertemperatur. Das alles
  verbraucht Energie und die Gesamtheit dieser Energie hat einen Namen. Es
  handelt sich hierbei um den <b>Grundumsatz</b>. Im Folgenden wirst du
  erfahren, wie du deinen Grundumsatz berechnen kann.
    </p>
    <h3>{`Wie berechnet man den Grundumsatz?`}</h3>
    <p>
  Viele Wege führen nach Rom. Gleiches gilt bei der Berechnung des
  Grundumsatzes. Hierfür gibt es mehrere Formeln, die zum Teil stark abweichende
  Ergebnisse liefern. Sehr rudimentäre Formeln berücksichtigen lediglich das
  Körpergewicht und lassen Alter, Größe und Geschlecht unberücksichtigt. Formeln
  die zuverlässige Ergebnisse liefern sind zum Beispiel die etwas in die Jahre
  gekommene <b>Harris-Benedict-Formel</b>. Eine darauf aufbauende und
  aktualisierte Fassung dieser Gleichung ist die <b>Mifflin-St.Jeor-Formel</b>.
  Da es für dich oberste Priorität sein sollte immer die neuesten
  wissenschaftlichen Erkenntnisse zu nutzen, wirst du im Folgenden die{" "}
  <b>Mifflin-St.Jeor-Formel</b> kennenlernen.
    </p>
    <p>
  <Listing mdxType="Listing">
    10 &#215; [Gewicht in Kilogramm] + 6,25 &#215; [Größe in cm] – 5 &#215;
    [Alter in Jahren] + S
  </Listing>
  Die Konstante <b>S</b> beträgt für <b>Frauen -161</b> und für <b>Männer 5</b>.
  Und keine Sorge, wenn dir etwas unklar ist. Gleich wirst du anhand eines Beispiels
  sehen, wie einfach die Formel verwendet wird.
    </p>
    <h4>{`Beispielrechnung zum Grundumsatz`}</h4>
    <p>
  Du lernst am Besten anhand von Beispielen? Da bist du nicht allein, deshalb
  wirst du jetzt eine Beispielrechnung präsentiert bekommen. Du wirst gleich
  sehen wie einfach die <b>Mifflin-St.Jeor-Formel</b> anzuwenden ist.
    </p>
    <p>
  Betrachten wir folgendes Beispiel: eine <b>20-jährige Frau</b> mit einer
  Körpergröße von <b>170 cm</b> und einem Gewicht von <b>70 kg</b>. In die
  Formel oben eingesetzt ergibt dies:
  <Listing mdxType="Listing">
    10 &#215; 70 + 6,25 &#215; 170 – 5 &#215; 20 - 161 = 1501,5 kcal
  </Listing>
  Erinnere dich außerdem daran, dass du für <b>S</b> bei einer Frau <b>-161</b>{" "}
  einsetzt.
    </p>
    <p>
  Wir erhalten also für den <b>Grundumsatz</b> einen Wert von <b>1501,5 kcal</b>
  . Das ist also die Energiemenge, die du deinem Körper zuführen musst, um die
  notwendigsten Körperfunktionen aufrechtzuerhalten. Körperliche Aktivitäten
  werden hier also nicht berücksichtigt. Dies behandeln wir im nächsten
  Abschnitt.
    </p>
    <h2>{`Was ist der Leistungsumsatz des Körpers?`}</h2>
    <p>
  Der gesamte Kalorienbedarf lässt sich in die zwei Komponenten{" "}
  <b>Grundumsatz</b> und <b>Leistungsumsatz</b> unterteilen. Den Grundumsatz
  hast du bereits kennengelernt und berechnet. Doch worum handelt es sich beim
  Leistungsumsatz?
    </p>
    <Image image={getImage(props.data.mdx.frontmatter.image_second)} imageAlt={props.data.mdx.frontmatter.image_second_alt} imageDesc={props.data.mdx.frontmatter.image_second_desc} imageAuthor={props.data.mdx.frontmatter.image_second_credit_text} imageLink={props.data.mdx.frontmatter.image_second_credit_link} mdxType="Image" />
    <p>
  Erinnern wir uns daran, dass der Grundumsatz die Kalorienmenge ist, die du
  verwendest, wenn du absolut nichts tust. Der Leistungsumsatz ist also die
  Energiemenge, welche du im Laufe des Tages verbrauchst, um beliebige
  körperliche Aktivitäten auszuführen. Hierzu zählt Stehen, Gehen, Laufen,
  Einkäufe tragen, Fahrrad fahren, Gewichte stemmen und so weiter.
  Zusammengefasst erhöht also ein aktiver Lebensstil, egal ob durch Arbeit oder
  Sport, die Kalorienmenge, die du verbrauchst und zwar zusätzlich zum
  Grundumsatz.
    </p>
    <p>
  Auch hier gibt es verschiedene Ansätze, um den Leistungsumsatz zu berechnen.
  Es gibt zum einen rechnerisch ziemlich aufwendige Verfahren und solche, die
  etwas einfacher sind. Erfahrungsgemäß führen die komplizierten Modelle nicht
  zu einem besseren Ergebnis. Der Grund hierfür ist, dass die jeweiligen
  Aktivitätslevel jeweils von dir geschätzt werden müssen. Auch ist nicht jeder
  Tag so wie der vorherige. Du erhältst also in jedem Fall, im Gegensatz zur
  ziemlich exakten Berechnung des Grundumsatzes, einen Schätzwert. Aus diesem
  Grund werden wir auf die zeitliche Gewichtung des Aktivitätsniveaus verzichten
  und im nächsten Abschnitt eine Methode kennenlernen mit der man zu
  gleichwertigen Ergebnissen kommt.
    </p>
    <h3>{`Wie berechnet sich der Leistungsumsatz?`}</h3>
    <p>
  Um den Leistungsumsatz zu ermitteln, musst du dein tägliches Aktivitätsniveau
  abschätzen. Du bekommst unten eine offizielle und wissenschaftlich ermittelte
  Tabelle zur Verfügung gestellt in der du deinen PAL-Wert ablesen kannst. PAL
  steht hierbei für <b>Physical Activity Level</b> und drückt als Zahl aus, wie
  hoch dein tägliches körperliches Aktivitätsniveau ist. Du suchst dir also in
  der Spalte <b>Aktivitätsniveau</b> die Beschreibung der täglichen Aktivität
  aus, die am Besten deine Situation beschreibt. Auch hier wirst du gleich
  anhand eines Beispiels sehen, wie du vorgehen kannst.
    </p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Aktivitätsniveau`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`PAL-Wert`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Beispiele`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ausschließlich sitzende oder liegende Lebensweise`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1,2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`alte, gebrechliche Menschen`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ausschließlich sitzende Tätigkeit mit wenig oder keiner anstrengenden Freizeitaktivität`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1,4-1,5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Büroangestellte, Feinmechaniker`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`sitzende Tätigkeit, zeitweilig auch zusätzlicher Energieaufwand für gehende oder stehende Tätigkeiten`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1,6-1,7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Laboranten, Kraftfahrer, Studierende, Fließbandarbeiter`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`überwiegend gehende und stehende Arbeit`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1,8-1,9`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hausfrauen, Verkäufer, Kellner, Mechaniker, Handwerker`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`körperlich anstrengende berufliche Arbeit`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2,0-2,4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bauarbeiter, Landwirte, Waldarbeiter, Bergarbeiter, Leistungssportler`}</td>
        </tr>
      </tbody>
    </table>
    <p>
  Bevor du jedoch ein Beispiel präsentiert bekommst, solltest du noch wissen,
  was der PAL-Wert rechnerisch aussagt. Ein <b>PAL-Wert</b> von z.B. <b>1,4</b>{" "}
  drückt aus, dass du zusätzlich zum Grundumsatz <b>40% mehr an Kalorien</b>{" "}
  verbraucht hast. Würdest du also den Grundumsatz mit dem PAL-Wert
  multiplizieren, würdest du den Gesamtumsatz berechnen. Um jedoch
  ausschließlich den <b>Leistungsumsatz</b> zu ermitteln, musst du{" "}
  <b>vom PAL-Wert 1 abziehen</b> und dann diesen Wert mit dem oben berechneten
  Grundumsatz multiplizieren. Das siehst du anhand der folgenden Formel.
  <Listing mdxType="Listing">Leistungumsatz = Grundumsatz &#215; (PAL-Wert - 1)</Listing>
    </p>
    <p>
  Neben dem Aktivitätsniveau in der obigen Tabelle, die überwiegend deine
  berufliche und alltägliche körperliche Betätigung beschreibt, wird hier eine
  sportliche Freizeitbetätigung nur unzureichend berücksichtigt.{" "}
  <b>
    Du kannst für sportliche Betätigungen oder für anstrengende
    Freizeitaktivitäten, die etwa 4 bis 5 Mal die Woche mit einer Dauer von 30
    bis 60 Minuten stattfinden, pro Tag 0,3 PAL-Einheiten hinzufügen.
  </b>{" "}
  Doch jetzt ist es an der Zeit für ein Beispiel, damit du genau nachvollziehen
  kannst, wie du deinen Leistungumsatz berechnen kannst.
    </p>
    <h4>{`Beispielrechnung zum Leistungsumsatz`}</h4>
    <p>
  Nehmen wir als Beispiel die <b>Frau</b> aus der obigen Berechnung des
  Grundumsatzes. Der <b>Grundumsatz</b> beträgt also <b>1501,5 kcal</b>, die
  Dame ist eine <b>Büroangestellte</b> (PAL-Wert 1,4) und geht{" "}
  <b>4 mal die Woche für etwa 1 Stunde in's Fitnessstudio</b>. Es werden also zu
  den 1,4 noch 0,3 addiert. Wir erhalten also einen <b>PAL-Wert von 1,7</b>. Da
  wir aber nur den Leistungsumsatz berechnen wollen, ziehen wir 1 ab und
  erhalten 0,7. Dies ist der Wert mit dem wir den Grundumsatz multiplizieren.
  <Listing mdxType="Listing">
    1501,5 kcal &#215; (1,4 + 0,3 - 1) = 1501,5 kcal &#215; 0,7 = 1051,05 kcal
  </Listing>
  Wir erhalten als <b>Leistungumsatz</b> einen Wert von <b>1051,05 kcal</b>.
    </p>
    <h2>{`Wie berechnet man seinen Gesamtumsatz?`}</h2>
    <p>
  Die Frage, die dich jedoch wahrscheinlich am meisten interessiert ist, wie
  viele Kalorien du insgesamt täglich verbrauchst. Dein tatsächlicher
  Kalorienbedarf ist die Summe aus Grund- und Leistungumsatz, wie du anhand
  dieser einfachen Gleichung erkennen kannst.
  <Listing mdxType="Listing">Gesamtumsatz = Grundumsatz + Leistungsumsatz</Listing>
  Wenn du also diese Kalorienmenge täglich zu dir nehmen würdest, würdest du
  weder ab- noch zunehmen, dein Körpergewicht würde konstant bleiben. Die
  Berechnung des Gesamtumsatzes sieht fast genauso aus, wie die Berechnung des
  Leistungumsatzes.
  <Listing mdxType="Listing">Gesamtumsatz = Grundumsatz &#215; PAL-Wert</Listing>
  Der Unterschied ist lediglich, dass man <b>nicht</b> 1 vom PAL-Wert abzieht.
  Der Gesamtumsatz ist also der Grundumsatz inklusive aller körperlicher
  Betätigungen.
    </p>
    <h3>{`Beispielrechnung zum Gesamtumsatz`}</h3>
    <p>
  Auch hier wirst du anhand eines Beispiels erkennen, wie einfach die Berechnung
  des Gesamtumsatzes ist. Wir nehmen wieder als Beispiel die Dame von oben mit
  einem Grundumsatz von <b>1501,5 kcal</b> und einem <b>PAL-Wert von 1,7</b>.
  Zur Erinnerung, sie ist <b>Büroangestellte</b> mit einem PAL-Wert von 1,4 und
  betätigt sich mehrmals die Woche sportlich, wodurch 0,3 PAL-Einheiten
  dazukommen. Die Berechnung des Gesamtumsatzes sieht also wie folgt aus:
  <Listing mdxType="Listing">
    1501,5 kcal &#215; (1,4 + 0,3) = 1501,5 kcal &#215; 1,7 = 2552,55 kcal
  </Listing>
  Der Kalorienbedarf der Büroangestelltin, die 4 mal die Woche für eine Stunde
  im Fitnessstudio trainieren geht, beträgt <b>2552,55 kcal</b>. Diesen Wert
  bezeichnet man auch als <b>Erhaltungskalorien</b>. Nimmt sie jeden Tag im
  Durchschnitt so viele Kalorien zu sich, bliebe ihr Gewicht konstant.
    </p>
    <p>
  Du siehst also, die Berechnung deines Kalorienbedarfs ist keine Wissenschaft.
  Selbstverständlich wäre es jedoch deutlich praktischer, wenn es dafür eine{" "}
  <NavLinkGlobal to="/de/#appstores" mdxType="NavLinkGlobal">App</NavLinkGlobal> gäbe, die das für einen
  erledigt. Am Besten wäre es, wenn diese{" "}
  <NavLinkGlobal to="/de/#appstores" mdxType="NavLinkGlobal">App</NavLinkGlobal> auch gleichzeitig die
  Kalorien zählen würde, die du täglich zu dir nimmst. Glücklicherweise gibt es
  einen solchen{" "}
  <NavLinkGlobal to="/de/#appstores" mdxType="NavLinkGlobal">Kalorienzähler</NavLinkGlobal>. Und das
  Schöne ist, dass er komplett kostenlos ist und uneingeschränkt genutzt werden
  kann. Worauf wartest du?{" "}
  <NavLinkGlobal to="/de/#appstores" mdxType="NavLinkGlobal">Fange am Besten jetzt an!</NavLinkGlobal>
    </p>
    <h2>{`FAQ - Häufig Gestellte Fragen`}</h2>
    <p>
  Kennst du das Gefühl, wenn du dich seit einer gefühlten Ewigkeit zu einem
  Thema schlau liest, du dann aber enttäuscht merkst, dass genau <b>DEINE</b>{" "}
  besondere Frage unbeantwortet bleibt? Kenne ich nur zu gut, deshalb bekommst
  du hier einen Fragen-Antworten Katalog mit dem auch deine speziellste Frage
  beanwortet wird. Los geht's!
    </p>
    <Image image={getImage(props.data.mdx.frontmatter.image_third)} imageAlt={props.data.mdx.frontmatter.image_third_alt} imageDesc={props.data.mdx.frontmatter.image_third_desc} imageAuthor={props.data.mdx.frontmatter.image_third_credit_text} imageLink={props.data.mdx.frontmatter.image_third_credit_link} mdxType="Image" />
    <h3>{`Was tun wenn Grundumsatz zu niedrig?`}</h3>
    <p>
  Wenn dein Grundumsatz höher ist, verbraucht dein Körper auch in Ruhe mehr
  Kalorien. Das ist praktisch, weil du dadurch einfacher schlank bleiben und
  dein Wunschgewicht halten kannst. Auch hilft dir ein höherer Grundumsatz dabei
  abzunehmen, falls das dein Ziel ist. Außerdem kannst du dadurch einfach mehr
  essen und seien wir mal ehrlich, wer tut das nicht gern!
    </p>
    <p>
  Du hast gesehen, dass dein Grundumsatz von deinem Geschlecht, Alter, Größe und
  Gewicht abhängig ist. Bis auf das Gewicht kannst du die anderen Größen nicht
  beeinflussen. Eine Erhöhung des Gewichts führt also zu einem höheren
  Grundumsatz. Doch hier trennt sich die Spreu vom Weizen. Eine Erhöhung des
  Fettanteils führt tatsächlich auch zu einem erhöhten Grundumsatz. Der Grund
  dafür ist, dass Körperfett Gewebe ist, dass auch durch Nährstoffe und Kalorien
  versorgt werden muss. Doch steht hier außer Frage, dass das eine ungesunde und
  unästhetische Lösung ist. Die einzig sinnvolle Alternative ist es für dich{" "}
  <b>Muskulatur aufzubauen</b>. Das hilft dir langfristig mehr Kalorien, auch in
  Ruhe, verstoffwechseln zu können. Auch hilft es deinen passiven
  Bewegungsapparat langfristig zu entlasten und hat zudem einen ästhetischen
  Mehrwert.
    </p>
    <h3>{`Wie kann ich meinen Kalorienverbrauch messen?`}</h3>
    <p>
  Nachdem du deinen Gesamtumsatz berechnet hast, möchtest du jetzt wissen, ob
  dieser deinem tatsächlichen Kalorienbedarf entspricht. Um dies zu ermitteln
  hast du zwei Möglichkeiten. Die erste Möglichkeit besteht darin, über zwei
  Wochen alles, was du isst zu tracken. Das heißt, du prüfst wie viele Kalorien
  du pro Tag zu dir nimmst. Zusätzlich solltest du dich etwa jeden 2. bis 3. Tag
  wiegen. Wenn du bei der jetzt ermittelten Kalorienmenge feststellst, dass dein
  Gewicht in etwa konstant geblieben ist, so entspricht diese Kalorienmenge
  deinem tatsächlichen Kalorienbedarf. Hast du hingegen zugenommen, so liegt
  deine zuführte Energiemenge über deinem Gesamtumsatz. Solltest du abgenommen
  haben, so hast du weniger gegesen, als es deinem tatsächlichen Kalorienbedarf
  entspricht.
    </p>
    <p>
  Ein anderer und wohl deutlich bequemerer und nachhaltigerer Ansatz ist es,
  einen
  <AffiliateLink url={props.pageContext.frontmatter.product_fitness_tracker_url} mdxType="AffiliateLink">
    {props.pageContext.frontmatter.product_fitness_tracker_text}
  </AffiliateLink> zu verwenden. Das angenehme an dieser Alternative ist es, dass
  es rein passiv passiert. Du ziehst den Tracker an und die Ermittlung deines Kalorienverbrauchs
  wird anhand sehr genauer Sensoren und Algorithmen ermittelt. Durch die derart gemessenen
  Körperwerte kommen äußerst zuverlässige und genaue Werte zustande. Du kannst beide
  Möglichkeiten austesten und sehen, was dir persönlich am ehesten zusagt.
    </p>
    <h3>{`Wie viele kcal zum Abnehmen?`}</h3>
    <p>
  Du hast deinen Gesamtumsatz ermittelt und möchtest jetzt wissen, um wie viele
  Kalorien du diesen reduzieren musst, um nachhaltig und gesund abzunehmen? Ein
  sehr guter Erfahrungswert sind hier <b>300 kcal</b>. Hierdurch hast du ein
  Wochendefizit von 2100 kcal und würdest rechnerisch{" "}
  <b>1,2 kg reines Körperfett</b> pro Monat verlieren. Das bedeutet, dass unsere
  Dame von oben mit einem Gesamtumsatz von <b>2552,55 kcal</b>, eine
  Kalorienmenge für ihre Diät von etwa <b>2250 kcal</b> ansetzen würde. Du wirst
  vielleicht von irgendwelchen selbsternannten Experten hören, dass dieses
  Defizit zu niedrig ist. Doch sei hier sehr vorsichtig. Ein Defizit von 300
  kcal sorgt dafür, dass dein Stoffwechsel nicht einschläft und du zudem noch
  jeden Tag ziemlich satt wirst. Das hilft dir enorm die Diät über eine längere
  Dauer entspannt durchzuziehen. Denn nur eine Diät, die du wirklich abschließt,
  ist auch eine gute Diät!
    </p>
    <h3>{`Wie viele kcal am Tag zum Zunehmen?`}</h3>
    <p>
  Um zuzunehmen, also Muskulatur aufzubauen, benötigst du einen leichten
  Kalorienüberschuss. Dieser Vorgang ist eine Gradwanderung, da es nicht dein
  Ziel ist fett zu werden, sondern kontrolliert und sauber Muskeln aufzubauen.
  Die Grundlage hierfür ist die Ermittlung deines Gesamtumsatzes. Hierbei ist es
  sehr wahrscheinlich, dass dieser bereits zu Beginn ausreicht, um zumindest in
  der Anfangsphase Muskulatur aufzubauen. Dieser wurde nämlich derart berechnet,
  dass praktisch jeder deiner Tage gleich abläuft. Da du aber wahrscheinlich
  nicht jeden Tag arbeitest oder Sport treibst, wird dein realer Verbrauch
  wahrscheinlich darunter liegen. Du hättest also hier bereits deinen leichten
  Überschuss.
    </p>
    <p>
  Kommt deine Gewichtszunahme zum Erliegen, so erhöhe deinen Gesamtumsatz um{" "}
  <b>300 bis 500 kcal</b>. Du solltest dein Körpergewicht und deine Körpermaße
  im Auge behalten. Besonders dein Körperfettanteil sollte nicht merklich
  ansteigen. Eine durschnittliche{" "}
  <b>Körpergewichtszunahme von 500 g pro Monat</b> sollte nicht wirklich
  überschritten werden. Trainingsanfänger werden hier zu Beginn schneller
  Erfolge verzeichnen. Aber für fortgeschrittene Sportler sollte dieser Wert
  nicht überschritten werden. 500 g pro Monat wären 6 kg Muskulatur im Jahr. Das
  wären nach 10 Jahren rechnerisch 60 kg reine Muskeln. Dass dies unrealistisch
  ist, kann sich wohl jeder denken. Dein Ziel sollte es also sein, kontrolliert
  und fettfrei Qualitätsmuskulatur aufzubauen!
    </p>
    <h2>{`Was hast du in diesem Artikel gelernt?`}</h2>
    <p>
  In diesem Artikel hast du alles Wissenswerte über die Berechnung deines
  Kalorienbedarfs gelernt. Jetzt hast du fundiertes Wissen und kennst die
  Unterschiede zwischen Grund-, Leistungs- und Gesamtumsatz. Die folgenden
  Punkte fassen nochmal für dich zusammen, was du dir merken solltest!
    </p>
    <ul>
      <li parentName="ul">{`Der Grundumsatz sagt dir, wie viele Kalorien du in Ruhe ohne körperliche Betätigung verbrauchst.`}</li>
      <li parentName="ul">{`Der Leistungsumsatz gibt an, wie viele Kalorien du zusätzlich zum Grundumsatz durch körperliche Aktivitäten benötigst.`}</li>
      <li parentName="ul">{`Der Gesamtumsatz ist die Summe aus Grund- und Leistungsumsatz, er gibt deinen täglichen Kalorienbedarf an.`}</li>
      <li parentName="ul">{`Du kannst deinen Grundumsatz durch Zunahme von Muskulatur erhöhen.`}</li>
      <li parentName="ul">
        <AffiliateLink url={props.pageContext.frontmatter.product_fitness_tracker_url} mdxType="AffiliateLink">
  {props.pageContext.frontmatter.product_fitness_tracker_text}
        </AffiliateLink> können dir helfen, um deine tatsächlich verbrauchten Kalorien
zu ermitteln.
      </li>
    </ul>
    <Disclosure mdxType="Disclosure" />
    <AppPromotion mdxType="AppPromotion" />
    <BlogOverview mdxType="BlogOverview" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      